/*************** 
* FONTS & VARA *
****************/
@font-face {
	font-family: headerFont;
	src: url(fonts/Abril_Fatface/AbrilFatface-Regular.ttf);
}

@font-face {
	font-family: paragraphFont;
	src: url(fonts/Literata/Literata-VariableFont_opsz,wght.ttf);
}


@font-face {
	font-family: emphasisFont;
	src: url(fonts/Viaoda_Libre/ViaodaLibre-Regular.ttf);
}

:root {
	--gold: #c49829; 
	--charcoal: #010000;
	--charcoal-normal: #272626; 
	--charcoal-alt: #261e12;
	--brown: #3a2e1e; //#593d25; #614c30 #3a2e1e
	--white: #ffffff;

	--map-grey: #f2f2f2;
	--map-brown: #593d25;
	--map-light-tan: #f2d4ae;
	--map-med-tan: #f2c185;
	--map-dark-tan: #a68358;

	--border-radius: 4px;
}

/********* 
* STYLES *
**********/

.leaflet-container {
	height: 100vh;
	opacity: 0.7;
	}

.leaflet-pane.leaflet-map-pane img.leaflet-marker-icon {
	background: transparent;
	border: none;
}

.App {
	background-color: var(--brown);
}

header.page-name {
	font-family: headerFont;
	margin: 0;
	position: absolute;
	top: 0.25em;
	right: calc(50% - 4em);
	z-index: 1000;
	font-size: 24px;
	left: 0;
	right: 0;
	text-align: center;
	pointer-events: none;

	img {
		width: 250px;
		filter: contrast(200%);
	}
}

.popup-button {
	background: transparent;
    border: none;
    cursor: pointer;
    padding: 0 8px;

    img {
    	width: 1em;
		position: relative;
		top: 0.125em;
    }
}

.js-large-icons .leaflet-marker-icon.leaflet-div-icon {
	width: 32px !important;
	height:  32px !important;
}

.js-medium-icons .leaflet-marker-icon.leaflet-div-icon {
	width: 18px !important;
	height:  18px !important;
}

.leaflet-div-icon.projects-icon {
	border-radius: 4px;
}

button.locate-me {
	z-index: 1000;
	position: absolute;
	right: 10px;
	top: 80px;
	outline: currentcolor none medium;
	width: 34px;
	height: 34px;
	border: 2px solid #a89c8a;
	padding:  2.5px;
	border-radius: var(--border-radius);
	cursor: pointer;

	img {
		width: 24px;
		height: 24px;
	}
}

.side-container  {
	z-index: 1000;
	position: absolute;
	top: 3.5em;
	left: 1em;
	overflow: hidden;
}

.search-box {
	font-family: paragraphFont;
	background-color: var(--brown);
	color: var(--white);
	border-radius: var(--border-radius);
	width: 300px;
	display: flex;
	align-items: center;
	font-family: inherit;
	padding: 0.5em;
	position: relative;
	z-index: 1001;

	input {
		background-color: transparent;
		color: var(--white);
		line-height: 2em;
		border: none;
		width: 192px;
		padding-bottom: 0.25em;
	}

	button {
		background-color: transparent;
		border: none;
		cursor: pointer;

		&.search-box-clear {
			color: var(--white);
			padding: 0.75em;
		}
		&.search-box-icon {
			padding-top: 0.25em;
		}
	}


	img {
		width: 2em;
		height: 2em;
		filter: invert(100%);
	}
}

@keyframes slideDown {
    0% {
        top: -494px;
    }
    100% {
        top: 4px;
    }
}

@keyframes slideUp {
    0% {
        top: 4px;
    }
    100% {
        top: -494px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

menu.slide-up {
	animation-name: slideUp;
    animation-duration: 500ms;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

// .leaflet-div-icon {
// 	animation-name: fadeIn;
//     animation-duration: 750ms;
//     animation-delay: 0s;
//     animation-fill-mode: forwards;
//     animation-iteration-count: 1;
// }

menu {
	padding: 0;
	background-color: var(--brown);
	color: var(--white);
	border-radius: var(--border-radius);
	width: 312px;
	position: relative;
	top: 4px;
	margin-top: 0.5em;
	animation-name: slideDown;
    animation-duration: 500ms;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    z-index: 1000;

	header {
		font-family: headerFont;
		color: var(--gold);
		font-size: 24px;
		text-align: center;
	}

	ul {
		list-style: none;
		padding: 0.25em 0;
		font-family: paragraphFont;

		li {
			border: 1px solid transparent;
			border-radius: var(--border-radius);
			padding: 0.25em;
			margin: 0.5em;

			&.selected {
				box-shadow: rgb(193, 193, 193) 0px 0px 5px 0px inset;
				border-color: var(--gold);
			}

			button {
				text-align: left;
				cursor: pointer;
				font-size: 14px;
				margin: 0;
				padding: 0;
				border: 0;
				overflow: visible;
				background-color: transparent;
				color: var(--white);
				display: flex;
				align-items: center;
				font-family: inherit;

				img {
					width: 60px;
					height: 60px;
					margin: 0.5em;
				}
			}
		}
	}
}
@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
.backdrop {
	z-index: 1003;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(27, 26, 26, 0.33);

	.modal-border {
		padding: 150px 180px 150px 200px;
		margin: 50px auto;
		max-width: 800px;
		max-height: 400px;
		max-height: 430px;

		.modal-content {
			width: 600px;
			max-width: calc(100% - 15em);
			margin: 10px auto;
			text-align: left;
			background-color: transparent;
			color: var(--white);
			padding: 1em;
			font-size: 14px;
			position: relative;
			overflow: visible;
			font-weight: 500;

			header {
				font-family: headerFont;
				color: var(--gold);
				font-size: 24px;
				text-align: center;
			}

			button {
				position: absolute;
				top: 25px;
				right: 20px;
				background: transparent;
				border: none;
				font-weight: bold;
				color: var(--gold);
				cursor: pointer;
			}

			a {
				color: var(--gold);
			}
		}
	}

	.page-spinner {
		width: 80px;
		position: absolute;
		top: calc(50% - 40px);
		left: calc(50% - 40px);
		animation: spin 2s linear infinite;
	    -webkit-animation: spin 1.5s linear infinite;
	}
	.page-spinner-text {
		width: 250px;
		position: absolute;
		top: calc(50% + 40px);
		left: calc(50% - 125px);
		text-align: center;
		font-size: 1.25em;
		font-weight: 700;
	}
}

.backdrop.is-dark {
	background-color: rgba(27, 26, 26, 0.8);
}

@media screen and (max-width: 1116px) {
	.backdrop .modal-border {
		border-image-width: 260px !important;	
		padding-right: 220px;

		.modal-content {
			margin: 0px;
			max-width: 100%;
			width: auto;
		}	
	}
}

@media screen and (max-width: 1050px) {
	.backdrop .modal-border {
		border-image-width: 200px !important;	
		padding-left: 152px;
		padding-right: 168px;
		padding-top: 120px;

		.modal-content {
			width: auto;
		}
	}
}

@media screen and (max-width: 828px) {
	.backdrop .modal-border {
		position: absolute;
		left: -80px;
		right: -80px;
	}
}

@media screen and (max-width: 672px) {
	.backdrop .modal-border {
		border-image-width: 200px 200px 120px 200px !important;
		left: -120px;
		right: -130px;
		bottom: -20px;
		top: 0;
		max-height: none;
		margin-bottom: 0;
	}
}

@media screen and (max-height: 750px) {
	.backdrop .modal-border {
		margin-top: 0;
	}
}


@media screen and (max-width: 500px) {
	.backdrop .modal-border {
		margin-top: 0;
	}
}


@media screen and (max-height: 700px) and (min-width: 432px) {
	.backdrop .modal-border {
		top: -80px;
		padding-bottom: 120px;
	}
}


@media screen and (max-height: 550px) {
	.backdrop .modal-border .modal-content {
		line-height: 1.2em;

		p, ul {
			margin: 4px;
		}
	}
}


@media screen and (min-width: 901px) {
	header.page-name {
		text-align: center;
		padding-right: 0;
		top: 0.25em;
	}
}

@media screen and (min-width: 641px) and (max-width: 900px) {
	header.page-name {
		top: 0.25em;
		text-align: right;
		padding-right: 2em;
	}

	.side-container {
		top: 4em;
	}
}

@media screen and (min-width: 641px) and (max-width: 900px) and (max-height: 600px) {
	header.page-name {
		top: -1em;
	}
}

@media screen and (max-width: 640px) {
	header.page-name {
		top: -1em;
		text-align: center;
		padding-right: 0;
	}

	.side-container {
		top: 6.5em;
		margin: 0.5em calc(50% - 156px);
		left: 0;
	}
	
	button.locate-me {
		top: 10px;
		left: 10px;
	}
}

@media screen and (min-width: 641px) and (max-height: 600px) {
	.side-container {
		top: 1em;
	}
}

@media screen and (max-height: 725px) and (max-width: 641px) {
	.backdrop .modal-content {
		margin-top: 72px;
		padding: 0.5em;
		line-height: 1.25em;

		header {
			font-size: 18px;
		}

		p, ul {
			margin: 5px 0;
		}
	}
}

@media screen and (max-width: 450px) and (max-height: 740px) {
	.backdrop .modal-content {
		margin-top: 72px;
		padding: 0.5em;
		line-height: 1.25em;

		header {
			font-size: 18px;
		}

		p, ul {
			margin: 5px 0;
		}
	}
}

@media screen and (max-height: 600px) {
	.side-container {
		menu {
			max-height: calc(100% - 3em);
			overflow: scroll;
			margin-top: 0;

			header {
				font-size: 1.5em;
			}

			ul {
				padding: 0;

				li {
					padding: 0.125em;
					margin: 0.25em;

					button {
						line-height: 1.2em;

						img {
							width: 40px;
							height: 40px;
							margin: 0.25em 0.5em 0.25em 0.25em;
						}
					}
				}
			}
		}
	}
}



